import http from '@/utils/http';

export const searchEquipment = (parameter) => {
  return http.request({
    url: '/v1/games/equipment/search',
    method: 'post',
    data: parameter
  })
}

export const getEquipments = (parameter) => {
  return http.request({
    url: '/v1/games/equipment/list',
    method: 'post',
    params: parameter
  })
}

export const editEquipment = (parameter) => {
  return http.request({
    url: '/v1/games/equipment/edit',
    method: 'post',
    data: parameter
  })
}